const ACLS = [
    { checked: false, name: "solicitacoesexec", label: "Exução de Solicitações", description: "Permitir o acesso a executar solicitações", group: "Solicitações" },
    { checked: false, name: "simularportabilidade", label: "Simular Portabilidade", description: "Permitir o acesso a simular portabilidade", group: "Portabilidade" },
    { checked: false, name: "solicitarpagtosaldo", label: "Solicitar Pagamento de Saldo Devedor", description: "Permitir o acesso a Solicitar Pagamento de Saldo Devedor", group: "Portabilidade" },
    { checked: false, name: "enviarparaconformidade", label: "Solicitar Envio para Conformidade", description: "Enviar a proposta para conformidade", group: "Portabilidade" },
    { checked: false, name: "solicitacoescrud", label: "Administração de Solicitações", description: "Permitir o acesso a administração de solicitações(Inclusao, Cancelamento, etc...)", group: "Solicitações" },
]

export function getAcls() {
    return ACLS;
}