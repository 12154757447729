import React, { useState, useEffect } from "react";

import Form from "../../components/form/simulator/data";
import { cidades } from "../../helpers/cidades";
import { mCPF, mDate, saveCookie, getCookie } from "../../helpers/general";
import { getData, sendData, buscarAutorizacao } from "../../helpers/auth";
import { Buttons } from "../../components/layout";
import { swalError } from "../../components/swal";
import { formatCurrency, formatFloat, } from "../../helpers/general";

import {
  validateDate,
  validateBirthDate,
  validateCPF,
  compareValues
} from "../../helpers/validation";
import { uriList } from "../../helpers/config";
import { isInvalid, send, goBack } from "../../helpers/core";

import { connect } from "react-redux";
import {
  setCliente,
  setAlterarProposta,
  setClienteDados,
  setProposta,
} from "../../store/actions/dados";
import { suppressDeprecationWarnings } from "moment";

function Data({ ...props }) {
  let estados = cidades();

  function getDataIni() {
    return {
      nome: "",
      cpf: "",
      civil: "casado",
      sexo: "masculino",
      ddn: "",
      matricula: "",
      matricula_pensionista: "",
      senha: "",
      mae: "",
      ufn: "AC",
      cidaden: "",
      salario: 0,
      proposta_uuid: props.proposta,
      ip: getCookie({ label: "ip" }),
    };
  }

  const [autorizacaoInvalida, setAutorizacaoInvalida] = useState(false);
  const [registroRFInvalido, setRegistroRFInvalido] = useState(false);
  const [carregando, setCarregando] = useState(false);
  const [data, setData] = useState(getDataIni());
  const [loadData, setLoadData] = useState({});
  const [showAlert, setAlert] = useState({
    nome: 0,
    cpf: 0,
    civil: 0,
    sexo: 0,
    ddn: 0,
    matricula: 0,
    matricula_pensionista: 0,
    senha: 0,
    mae: 0,
    ufn: 0,
    cidaden: 0,
    salario: 0,
  });
  const [loaded, setLoaded] = useState(false);
  const [isAllowed, setAllowed] = useState(true);

  const [alternate, setAlternate] = useState({
    cpf: 0,
    ddn: 0,
  });

  const [alternateMessage, setAlternateMessage] = useState({
    cpf: "",
    ddn: "",
  });

  function handleChange(e, userData) {
    console.log("data.handleChange(0)")
    let target = e.currentTarget;
    let name = target.name;
    let value = userData || target.value;

    if (name == "salario") {
      if (compareValues(value, 50000)) {
        console.log("handleChange(salario)(0)")
        setAlert({ ...showAlert, [name]: 1 });
      }
      else {
        console.log("handleChange(salario)(1)")
        setData({ ...data, salario: formatCurrency(value) });
      }
    } else {
      name === "ufn"
        ? setData({
          ...data,
          [name]: value,
          cidaden: getFirstCity(value),
        })
        : setData({ ...data, [name]: value });
    }

    if (data && data[name] && data[name].length === 1 && target.value === "") {
      setAlert({ ...showAlert, [name]: 1 });
    } else {
      clearAlert(e);
    }
  }

  function getFirstCity(value) {
    let _estados = estados.estados.filter(
      (estados, i) => estados.sigla === value
    );
    return _estados[0]?.cidades[0];
  }

  function handleNome(e) {
    let target = e.currentTarget;
    let nums = /[0-9]/i;

    if (nums.exec(target.value) === null) {
      handleChange(e);

      return false;
    }

    if (data[target.name].length === 1 && target.value === "") {
      handleChange(e);

      return false;
    }

    return false;
  }

  function CarregarDados(cpf) {
    console.log("CarregarDados(cpf): ", cpf);
    console.log("CarregarDados(user): ", props.user);
    if (
      !props.user ||
      props.user?.tipo === "AGENTE" ||
      props.user?.tipo === "CLIENTE"
    )
      return;
    setCarregando(true);
    sendData({
      uri: uriList("pessoa"),
      content: { cpf },
      signal: null,
      method: "POST",
    })
      .then((res) => {
        if (res.status && res.status > 200) throw new Error(res.message);
        let _data = res.data;

        //-- Gravando cliente
        props.alterarCliente(res.data.id);
        props.setClienteDados(res.data);
        setLoadData(res.data);

        console.log("simulator.data(dados): ", res.data);

        //-- Atribuindo dados
        for (let d in data) {
          if (["salario", "patrimonio"].indexOf(d) >= 0) {
            console.debug("simulator.data(d): ", d, res[d]);
            setData((previous) => ({ ...previous, [d]: formatCurrency(formatFloat(res[d])) }));
          }
          else if (_data[d] && d !== "ddn" && d !== "proposta_uuid") {
            setData((previous) => ({ ...previous, [d]: _data[d] }));
          } else if (_data[d] && d === "ddn") {
            let date = new Date(_data[d]);
            date = date.toLocaleDateString();
            setData((previous) => ({ ...previous, [d]: date }));
          } else if (_data[d] && d === "proposta_uuid") {
            if (!data[d])
              setData((previous) => ({ ...previous, [d]: _data[d] }));
          }
        }

        if (res.data.disableRF)
          setRegistroRFInvalido(true)

        setCarregando(false);
      })
      .catch((e) => {
        setCarregando(false);
        // setData({ ...getDataIni(), cpf })
      });

    console.log("simulator.data - consultando restrições!");
    sendData({
      uri: uriList("consultarRestricoes"),
      content: { cpf },
      signal: null,
      method: "POST",
    })
      .then((res) => {
        console.log("simulator.data - consulta de restrições: ", res);

        // text: res.message + " " + JSON.stringify(res.data),
        if (res.status && res.status > 200) {
          setAllowed(false)

          swalError({
            title: "Operação não poderá ser iniciada, favor entrar em contato com a Plataforma."
          });
        }
        else
          setAllowed(true)
      })
      .catch((e) => {
        console.log("simulator.data - consulta de restrições(erro): ", e);
        console.log(e);
      });
  }

  function onBlur(e) {
    // if (e.currentTarget.name === "cpf") {
    //     props.alterarCliente(null)
    //     let cpf = e.currentTarget.value
    //     if (cpf && cpf.length === 14) {
    //         CarregarDados(cpf)
    //     }
    // }
    // console.log(props.cliente)
  }

  async function handleCPF(e) {
    console.log("data.handleCPF: ", e.currentTarget.value);
    let nums = /\d/;
    if (nums.test(e.currentTarget.value)) {
      let cpf = mCPF(e.currentTarget.value);

      handleChange(e, cpf);

      setAlternate({ ...alternate, cpf: 0 });

      if (cpf.length === 14) {
        let isValid = validateCPF(cpf);

        if (!isValid) {
          setAlternate({ ...alternate, cpf: 1 });
          setAlternateMessage({
            ...alternateMessage,
            cpf: "CPF inválido!",
          });
        } else {
          let _autorizacao = await buscarAutorizacao({
            cpf,
            convenio_id: props.detalhes.convenio_id,
            tipo_convenio: props.detalhes.tipo_convenio
          });
          console.log("data.handleCPF(1): ", _autorizacao)
          if (!_autorizacao) {
            setAutorizacaoInvalida(true);
            return;
          }
          CarregarDados(cpf);
        }
      }

      setAutorizacaoInvalida(false);
      return false;
    }

    if (data.cpf.length === 1 && e.currentTarget.value === "") {
      handleChange(e);
      return false;
    }

    return false;
  }

  function handleData(e) {
    let nums = /[0-9]/;

    if (nums.test(e.currentTarget.value)) {
      let ddn = mDate(e.currentTarget.value);

      handleChange(e, ddn);
      setAlternate({ ...alternate, ddn: 0 });

      if (ddn.length === 10) {
        let date = isDateValid(ddn);

        if (date === "invalid") {
          setAlternate({ ...showAlert, ddn: 1 });
          setAlternateMessage({
            ...alternateMessage,
            ddn: "Data inválida",
          });
        }

        if (date === "birth") {
          setAlternate({ ...alternate, ddn: 1 });
          setAlternateMessage({
            ...alternateMessage,
            ddn: "Idade não permitida",
          });
        }
      }

      return false;
    }

    if (data.ddn.length === 1 && e.currentTarget.value === "") {
      handleChange(e);
      return false;
    }

    return false;
  }

  function clearAlert(e) {
    let name = e.currentTarget.name;

    setAlert({ ...showAlert, [name]: 0 });
  }

  function isDateValid(date) {
    let isValid = validateDate(date);
    let isAccept = validateBirthDate(date);

    if (!isValid) return "invalid";
    if (!isAccept) return "birth";

    return true;
  }

  function toogleBack() {
    if (!props.user) {
      goBack(data, "dados", props.toggleStep);
    } else {
      if (props.alterarProposta)
        props.setAlterarProposta(false, props.detalhes);

      return;
    }
  }

  function submit(callback) {
    let invalid = isInvalid(data);

    if (!invalid && isAllowed) {
      let newData = Object.assign(data);
      if (newData.salario) {
        console.log(newData.salario);
        newData.salario = newData.salario.replace(".", "").replace(",", ".");
        newData.salario = parseFloat(newData.salario);
      }

      for (let n in newData) {
        if (
          newData[n] &&
          n !== "senha" &&
          n !== "proposta_uuid" &&
          n !== "salario"
        )
          try {
            newData[n] = newData[n].toUpperCase();
          } catch (e) { }
      }

      localStorage.setItem("CPF", newData.cpf);
      send(
        {
          uri: uriList("pessoas"),
          content: { ...newData, proposta_uuid: props.proposta, },
          obj: newData,
          key: "dados",
        },
        props.toggleStep,
        saveCookie,
        props.alterarCliente,
        callback
      );

      return false;
    } else if (callback) callback(false);

    setAlert(invalid);
  }

  useEffect(() => {

    carregarDados()
    // eslint-disable-next-line
  }, []);

  async function carregarDados() {
    console.log("data.useEffect(detalhes): ", props.detalhes);
    console.log("data.useEffect(cliente): ", props.cliente);
    if (props.cliente) {
      setCarregando(true);
      console.log("data.useEffect(carregando-0)");
      await getData({ uri: uriList("pessoas") + "/" + props.cliente })
        .then((res) => {
          if (res.status && res.status > 200) throw new Error(res.message);

          console.log("data.useEffect(res-carregando-1)");
          for (let d in data) {
            if (res[d] && d !== "ddn" && d !== "proposta_uuid") {
              let value = res[d];
              if (d == "salario") {
                console.log("data.useEffect(salário): ", value);
                // value = formatCurrency(parseFloat(value).toFixed(2));
                value = formatCurrency(formatFloat(value));
                console.log("data.useEffect(salário-1): ", value);
                // value = parseFloat(value).toFixed(2)
              }
              setData((previous) => ({ ...previous, [d]: value }));
              res[d] = value;
            } else if (res[d] && d === "ddn") {
              let date = new Date(res[d]);
              date = date.toLocaleDateString();

              setData((previous) => ({ ...previous, [d]: date }));
              res[d] = date;
            } else if (res[d] && d === "proposta_uuid") {
              if (!data[d])
                setData((previous) => ({
                  ...previous,
                  [d]: res[d],
                }));
            }
          }

          console.log("data.useEffect(res-carregando-2)");

          setLoaded(true);
          setCarregando(false);
          props.setClienteDados(res);
        })
        .catch((e) => {
          setCarregando(false);
        });
    } else {
      console.log("data.useEffect(detalhes-1): ", props.detalhes);
      setLoaded(true);
    }
  }

  return (
    <>
      <fieldset className="col-12">
        <h4
          className="display-4 text-center font-weight-bold"
          style={{ marginBottom: "15px", color: "#3E6C84" }}
        >
          {props.title}
        </h4>

        {!carregando ? (
          <div className="form">
            <Form
              estados={estados}
              data={data}
              change={handleChange}
              CPF={handleCPF}
              onBlur={onBlur}
              ddn={handleData}
              nome={handleNome}
              warning={showAlert}
              alternate={alternate}
              alternateMessage={alternateMessage}
              loadData={loadData}
              registroRFInvalido={registroRFInvalido}
            />
          </div>
        ) : (
          <div style={{ textAlign: "center" }}>
            <br />
            <h2>Carregando dados...</h2>
          </div>
        )}

        {loaded ?
          (!props.user || props.alterarProposta ? (
            <Buttons
              goBack={toogleBack}
              submit={submit}
              disabledSecond={autorizacaoInvalida || registroRFInvalido || !isAllowed}
            />
          ) : (
            <Buttons submit={submit} />
          )) :
          <Buttons submit={submit} disabledSecond={true} disableFirst={true} disabled={true} />
        }
      </fieldset>
    </>
  );
}

function mapStateToProps(state) {
  return {
    proposta: state.dados.proposta,
    cliente: state.dados.cliente,
    user: state.dados.user,
    alterarProposta: state.dados.alterarProposta,
    detalhes: state.dados.detalhes,
  };
}

function mapActionToProps(dispatch) {
  return {
    alterarCliente(novoCliente) {
      dispatch(setCliente(novoCliente));
    },
    setAlterarProposta(alterar) {
      dispatch(setAlterarProposta(alterar));
    },
    setClienteDados(novoCliente) {
      dispatch(setClienteDados(novoCliente));
    },
    alterarProposta(novaProposta) {
      dispatch(setProposta(novaProposta));
    },
  };
}

export default connect(mapStateToProps, mapActionToProps)(Data);
