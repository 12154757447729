import React, { useState, useEffect } from "react";
import { connect } from "react-redux";


import { setProposta, setClienteDados } from "../../../../store/actions/dados";
import { uriList } from "../../../../helpers/config";
import { sendData } from "../../../../helpers/auth";
import { width } from "../../../../helpers/general";


const fluxoIni = {
    "proposta": {
        "numero": null,
        "tipo": "",
        "cliente": "",
        "cpf": ""
    },
    "fluxos": [
        {
            "id": 1,
            "status": "Incluida",
            "data": null,
            "imagem": "none",
        },
        {
            "id": 2,
            "status": "Análise Automática",
            "data": null,
            "imagem": "none",
        },
        {
            "id": 3,
            "status": "Assinatura",
            "data": null,
            "imagem": "none",
        },
        {
            "id": 4,
            "status": "Enviada para Banco",
            "data": null,
            "imagem": "none",
        },
        {
            "id": 5,
            "status": "Aprovada Banco",
            "data": null,
            "imagem": "none",
        },
        {
            "id": 6,
            "status": "Solicitação de Saldo",
            "data": null,
            "imagem": "none",
        },
        {
            "id": 7,
            "status": "Pagamento de Saldo",
            "data": null,
            "imagem": "none",
        },
        {
            "id": 8,
            "status": "Proposta Averbada",
            "data": null,
            "imagem": "none",
        }
    ]
}

// "imagem": "check", "none": "hourglass", "cancel", "error"
const FluxoStatusProposta = ({ ...props }) => {
    const [fluxo, setFluxo] = useState(fluxoIni)

    useEffect(() => {
        console.log('FluxoStatusProposta.useEffect: ', props.detalhes)
        obterDados()
    }, [props.detalhes])

    useEffect(() => {
        console.log('FluxoStatusProposta.useEffect(fluxo): ', fluxo)
    }, [fluxo])

    async function obterDados() {
        setFluxo(fluxoIni)
        await sendData({
            uri: uriList("fluxoProposta"),
            content: {
                proposta_uuid: props.proposta
            },
            method: "POST",
        })
            .then((res) => {
                console.log("FluxoStatusProposta(OK): ", res);
                if (res.status && parseInt(res.status) > 200) throw new Error();
                setFluxo(res?.data);
                console.log("FluxoStatusProposta(OK-Data): ", res.data);
            })
            .catch((e) => {
                console.log("FluxoStatusProposta(erro)");
                console.log(e)
            });
    }

    return (
        <div className=" col-12 mt-1 mb-2" style={{ width: "100%", }}>
            {props.clicked &&
                <div className="card p-0 px-0 py-0" style={{
                    borderColor: fluxo?.proposta?.cancelada ? "red" : fluxo?.proposta?.concluida ? "green" : fluxo?.proposta?.pendente ? "#fbba19" : "#939292",
                    borderRadius: width() == "mobile" ? "10px" : ""
                }}>
                    {width() != "mobile" &&
                        <div className="card-header p-0 px-1" style={{ display: 'flex', justifyContent: 'space-between', height: '30px' }}>
                            {
                                <><div style={{ fontWeight: "bold" }}>
                                    <p>{props?.detalhes?.nome?.toUpperCase()} - {props?.detalhes?.cpf}</p>
                                </div>
                                    <div className='d-flex'>
                                        <p>Proposta n°:</p>
                                        <p className='ml-3'>{props?.detalhes?.cedula_numero}</p>
                                    </div>
                                    {/* <p>{props?.detalhes?.operacao + " - " + props?.detalhes?.posicao}</p> */}
                                    <p>{props?.detalhes?.operacao}</p>
                                </>
                            }
                        </div>
                    }
                    <div
                        className="card-body p-0 px-1"
                        style={{
                            background: 'linear-gradient(135deg, #f0f0f0 0%, #d9d9d9 100%)',
                            borderRadius: width() == "mobile" ? "10px" : ""
                        }}
                    >
                        <div className="d-flex justify-content-between align-items-center p-0" style={{ borderRadius: width() == "mobile" ? "10px" : "" }}>
                            {width() !== "mobile" &&
                                fluxo?.fluxos &&
                                fluxo.fluxos.map((_fluxo) => (
                                    <div key={_fluxo.id} className="text-center p-0">
                                        <div className="mb-0 p-0">
                                            {_fluxo.imagem === 'check' && (
                                                <i className={`mdi mdi-check-circle ${fluxo?.proposta?.cancelada ? "text-danger" : "text-success"}`} style={{ fontSize: '30px' }}></i>
                                            )}
                                            {_fluxo.imagem === 'hourglass' && (
                                                <i className={`mdi mdi-timer-sand ${fluxo?.proposta?.cancelada ? "text-danger" : "text-warning"}`} style={{ fontSize: '30px' }}></i>
                                            )}
                                            {_fluxo.imagem === 'none' && (
                                                <i className={`mdi mdi-checkbox-blank-circle-outline ${fluxo?.proposta?.cancelada ? "text-danger" : "text-secondary"}`} style={{ fontSize: '30px' }}></i>
                                            )}
                                        </div>
                                        <p className="mb-0 p-0" style={{ fontSize: '13px' }}>{_fluxo.status}</p>
                                        <small style={{ fontSize: '11px' }}>{_fluxo.data}</small>
                                    </div>
                                ))
                            }
                            {width() === "mobile" &&
                                fluxo?.fluxoMobile &&
                                fluxo.fluxoMobile.map((_fluxo) => (
                                    <div key={_fluxo.id} className="text-center p-0">
                                        <div className="mb-0 p-0">
                                            {_fluxo.imagem === 'check' && (
                                                <i className={`mdi mdi-check-circle ${fluxo?.proposta?.cancelada ? "text-danger" : "text-success"}`} style={{ fontSize: '30px' }}></i>
                                            )}
                                            {_fluxo.imagem === 'hourglass' && (
                                                <i className={`mdi mdi-timer-sand ${fluxo?.proposta?.cancelada ? "text-danger" : "text-warning"}`} style={{ fontSize: '30px' }}></i>
                                            )}
                                            {_fluxo.imagem === 'none' && (
                                                <i className={`mdi mdi-checkbox-blank-circle-outline ${fluxo?.proposta?.cancelada ? "text-danger" : "text-secondary"}`} style={{ fontSize: '30px' }}></i>
                                            )}
                                        </div>
                                        <p className="mb-0 p-0" style={{ fontSize: '13px' }}>{_fluxo.status}</p>
                                        <small style={{ fontSize: '11px' }}>{_fluxo.data}</small>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>}
        </div>
    );
}

function mapStateToProps(state) {
    return {
        user: state.dados.user,
        proposta: state.dados.proposta,
        detalhes: state.dados.detalhes,
        clienteDados: state.dados.clienteDados,
    };
}

function mapActionToProps(dispatch) {
    return {
        alterarProposta(novaProposta) {
            dispatch(setProposta(novaProposta));
        },
        setClienteDados(novoCliente) {
            dispatch(setClienteDados(novoCliente));
        },
    };
}


export default connect(mapStateToProps, mapActionToProps)(FluxoStatusProposta);


