import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import d4sign from "../admin/d4Sign/d4SignEmbed";
import { width } from "../../helpers/general";
import * as moment from "moment";
import { connect } from "react-redux";

function EmbedD4Sign(props) {
  const [loaded, setLoaded] = useState(false);

  function toggleAccept() {
    if (props.onAccept) props.onAccept();
  }

  useEffect(() => {
    let ddn = moment(props.data.ddn).format("DD/MM/YYYY");
    // console.log("EmbedD4Sign - ddn: ", ddn)

    let signer = {
      email: props.data.email, //"diogops@gmail.com",
      display_name: props.data.nome, //"Diogo Daniel Pires Hitacarambi", //optional,
      documentation: props.data.cpf, //"123.321.123-40", //optional,
      birthday: ddn, //"22/10/1979", //optional,
      disable_preview: "0", //optional
      key_signer: props.data.key_signer, //"MTY0NjE0OTQ="
    };
    // console.log("Dados do documento: ", props.data)
    // console.log("Signer: \n", signer)

    d4sign.CarregarEmbed(
      {
        container: "signature-div",
        key: props.data.uuid_documento, //"7ce4e076-f0f6-4580-8066-59ed1bcb28d7",
        protocol: "https",
        host: "secure.d4sign.com.br/embed/viewblob",
        signer,
        // width: '100%',
        // height: width() !== 'mobile' ? '620px' : '370px',
        width: "100%",
        height: "100%",
        callback: function (event) {
          console.log("D4Sign - Proposta: ", props.detalhes);
          console.log("D4Sign - CallBack: ", event);
          if (event && event.data === "signed") {
            toggleAccept();
          } else if (!event) setLoaded(true);
        },
      },
      window
    );
  }, []);

  return (
    <>
      <Modal
        size={width() !== "mobile" ? "lg" : "md"}
        show={true}
        animation={false}
        backdrop="static"
        centered={true}
      >
        <Modal.Header className="formsign-header-custom">
          <strong>Assinatura de Proposta</strong>
        </Modal.Header>

        <Modal.Body
          style={{
            height: width() !== "mobile" ? 650 : 400,
            backgroundColor: "#E4E3E3",
            padding: "0px"
          }}
        >
          {!loaded && (
            <p
              style={{
                textAlign: "center",
                alignSelf: "center",
                backgroundColor: "lightgray",
                color: "darkblue",
                width: "100%",
              }}
            >
              !!! Carregando documento !!!
            </p>
          )}

          <div
            style={{ width: "100%", height: "100%" }}
            id="signature-div"
          ></div>
        </Modal.Body>
        <Modal.Footer className="formsign-footer-custom">
          <div>
            <div style={{ fontSize: 20 }}>
              <p style={{ textAlign: "center", fontWeight: "bold" }}>
                Atenção:{" "}
              </p>
              <p>A Assinatura da proposta não é garantia de sua aprovação!</p>
            </div>
            <div style={{ fontSize: 15 }}>
              <p>
                Clique{" "}
                <a
                  href={
                    props.data.tipo_assinatura == "portabilidade"
                      ? props.data.url_port
                      : props.data.tipo_assinatura == "portabilidade"
                        ? props.data.url_refinport
                        : props.data.url
                  }
                >
                  aqui
                </a>{" "}
                para imprimir
              </p>
              {/* <p >Clique <a href="https://incontadig.s3.amazonaws.com/2021/5/4552190e-74d3-413b-91e9-42f9836fd45e/da05eqv5qxr4154ywj9ybc_1622031506392.pdf"> */}
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}

function mapStateToProps(state) {
  return {
    user: state.dados.user,
    proposta: state.dados.proposta,
    detalhes: state.dados.detalhes,
  };
}

export default connect(mapStateToProps)(EmbedD4Sign);
