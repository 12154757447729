import React, { useState, useEffect } from "react";

import Form from "../../components/form/simulator/bank";

import { Buttons } from "../../components/layout";
import { uriList } from "../../helpers/config";
import { isInvalid, send, goBack } from "../../helpers/core";
import { bancos } from "../../helpers/bancos";
import Modal from "../../components/modal";
import { getCookie } from "../../helpers/general";

import { connect } from "react-redux";

function Bank({ ...props }) {
  let _bancos = bancos();
  let dataIni = {
    banco: "",
    agencia: "",
    conta: "",
    t_conta: "corrente",
    id: props.cliente,
    ip: getCookie({ label: "ip" }),
    proposta_uuid: props.proposta,
  };

  const [data, setData] = useState(dataIni);
  const [modal, setModal] = useState("");

  const [showAlert, setAlert] = useState({
    banco: 0,
    agencia: 0,
    conta: 0,
    t_conta: 0,
  });

  const openInfoModal = ({ title, text, list, bold }) => {
    setModal(
      <Modal
        title={title}
        text={text}
        list={list}
        bold={bold}
        onClick={() => setModal("")}
      />
    );
  };

  function handleChange(e, innerData) {
    let target = e.currentTarget;
    if (target.name === "banco")
      setData({ ...data, [target.name]: innerData || target.value });
    else if (target.name === "t_conta")
      setData({ ...data, [target.name]: innerData || target.value });
    else if (target.name == "conta")
      setData({ ...data, [target.name]: (innerData || target.value || "")?.replace(/\D/g, ""), });
    else
      setData({
        ...data,
        [target.name]: innerData || target.value.replace(/[a-zA-Z]/, ""),
      });

    if (data[target.name].length === 0 && target.value === "") {
      setAlert({ ...showAlert, [target.name]: 1 });
    } else {
      clearAlert(e);
    }
  }

  function handleAlpha(e) {
    let target = e.currentTarget;
    target = target.value.replace(/[0-9]/, "");

    console.log("handleAlpha: ", target.value);
    handleChange(e, target);

    return false;
  }

  //-- Funcao para limpar o alerta de campo invalido
  function clearAlert(e) {
    let target = e.currentTarget;

    setAlert({ ...showAlert, [target.name]: 0 });
  }

  function submit(callback) {
    let invalid = isInvalid(data);

    if (!invalid) {
      let newData = Object.assign(data);

      for (let n in newData) {
        if (
          newData[n] &&
          typeof newData[n] === "string" &&
          n !== "ip" &&
          n !== "id" &&
          n !== "t_conta"
        )
          newData[n] = newData[n].toUpperCase();
      }

      send(
        {
          uri: uriList("pessoas"),
          content: { ...newData, proposta_uuid: props.proposta, },
          obj: newData,
          key: "bancarios",
        },
        props.toggleStep,
        false,
        false,
        callback
      );

      for (let d in data) props.clienteDados[d] = data[d];

      return false;
    } else if (callback) callback(false);

    setAlert(invalid);
  }

  useEffect(() => {
    for (let d in data) {
      if (d === 'banco')
        setData((previous) => ({ ...previous, [d]: String(props.clienteDados[d]).padStart(3, '0') }));
      else if (d === 'agencia')
        setData((previous) => ({ ...previous, [d]: String(props.clienteDados[d]).padStart(4, '0') }));
      else if (props.clienteDados[d])
        setData((previous) => ({ ...previous, [d]: props.clienteDados[d] }));
    }
  }, []);

  return (
    <fieldset className="col-12"
      style={{
        overflowY: props.preencherDados ? 'auto' : 'visible',
        maxHeight: props.preencherDados ? '700px' : 'none',
      }}>
      <h4
        className="display-4 text-center font-weight-bold"
        style={{ marginBottom: "15px", color: "#3E6C84" }}
      >
        {props.title}
      </h4>

      {modal}
      <div className="form">
        <Form
          data={data}
          handleChange={handleChange}
          modal={openInfoModal}
          warning={showAlert}
          nums={handleChange}
          alpha={handleAlpha}
          bancos={_bancos}
          leilaoInss={props.leilaoInss}
        />
      </div>

      <Buttons
        goBack={() => goBack(data, "bancarios", props.toggleStep)}
        submit={submit}
      />
    </fieldset>
  );
}

function mapStateToProps(state) {
  return {
    proposta: state.dados.proposta,
    cliente: state.dados.cliente,
    clienteDados: state.dados.clienteDados,
  };
}

export default connect(mapStateToProps)(Bank);
